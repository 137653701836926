.inviteListPage {
    width: 100vw;
    height: 100vh;
    overflow: scroll;

    .createButton {
      position: fixed;
      bottom: 20px;
      right: 20px;
      width: 50px;
      height: 50px;
      background-color: #00a6ff;
      color: #FFF;
      font-size: 25px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 25px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        transform: rotate(180deg) scale(1.2);
      }
    }

    .title {
        margin: 20px 0;
        font-weight: bold;
        font-size: 30px;
    }

    .summaryContianer {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      

      .metric {
        flex: 1;

        &:not(:first-child) {
          margin-left: 10px;
        }

        .header {
          color: #00a6ff;
          font-size: 18px;
        }

        .content {
          font-weight: bold;
          font-size: 25px;

          p {
            margin: 5px;
          }
        }
      }
    }
}

@media (min-width: 768px) {
  .inviteListPage {
    margin: 50px;
  }
}