.pageLoader {
    transform: translate(0%, 0%);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10000;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: all;
    }
}