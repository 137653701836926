* {
  box-sizing: border-box;
  color: rgb(51, 51, 51);
}

html,
body,
#root,
#confetti {
  width: 100%;
  height: 100%;
}

#confetti {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
}
