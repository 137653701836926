#cardBack {
    >* {
        position: absolute;
        color: rgb(51, 51, 51);
    }

    #background {
        left: 50px;
        top: 50px;
        width: calc(100% - 100px);
        height: calc(100% - 100px);
        background-color: rgb(254, 251, 246);
    }

    >#title {
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        font-family: "Plantin", Helvetica, Arial, sans-serif;
        font-size: 70px;
        overflow: visible;
        white-space: nowrap;
    }

    .attendenceButton {
        position: absolute;
        top: 220px;
        transition: all 0.5s;

        &#attendButton {
            left: 230px;

            &.expended {
                z-index: 1;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &#notAttendButton {
            left: 570px;
        }
    }

    .attendenceButton.expended {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .youHaveReplied {
        position: absolute;
        top: 195px;
        width: 300px;
        font-size: 20px;
        color: rgba(51, 51, 51);
        text-align: center;
        font-family: 'Basis Grotesque Arabic Pro Md', Helvetica, Arial, sans-serif;

        &.attend {
            left: 230px;
        }

        &.notAttend {
            left: 570px;
        }
    }

    #confirmedInfo {
        position: absolute;
        top: 310px;
        left: 230px;
        width: 300px;
        font-size: 20px;
        text-align: center;
        font-family: 'Basis Grotesque Arabic Pro Md', Helvetica, Arial, sans-serif;
    }

    .info {
        font-family: "Courier", Calibri, Tahoma, sans-serif;
        bottom: 50px;
        font-size: 30px;
        text-align: center;
        left: 0;

        &#msg {
            width: 100%;
            top: 380px;
            padding-left: 200px;
            padding-right: 200px;
        }

        &#date {
            top: 540px;
            left: 50px;
        }

        &#address {
            top: 540px;
            left: 550px;
        }

        &.meta {
            width: calc(50% - 40px);
            text-align: center;

            .title {
                width: 100%;
                font-size: 25px;
                background: rgb(128, 110, 65);
                background: linear-gradient(90deg, rgba(128, 110, 65, 1) 0%, rgba(192, 172, 99, 1) 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: bold;
            }

            .content {
                width: 100%;
                font-size: 35px;
                margin-top: 20px;
            }
        }
    }
}

.attendButtonBody {
    width: 100%;
    text-align: center;

    .section {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 30px 0;

        &.bottom {
            flex-direction: column;

            .errMsg {
                margin-bottom: 10px;
            }
        }

        .quantityCell {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 30px;

            .quantityDesc {
                font-size: 12px;
                margin-top: 10px;
            }
        }
    }
}