.invitationPage {
  position: relative;
  width: max(100vw, 100vh);
  height: max(100vw, 100vh);
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: all 1s;
  pointer-events: none;
  background-color: rgb(255, 255, 248);

  &.ready {
    pointer-events: all;
  }

  .bkg__spotlight {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(75% 35% at 50% 95%, rgba(80, 82, 98, 0.6) 20%, rgba(80, 82, 98, 0) 90%),
            linear-gradient(rgba(28, 35, 50, 0) 0%, rgba(28, 35, 50, 1) 80%);
    z-index: -1;
    
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      top: 0; 
      left: 0;
      bottom: 0;
      right: 0;
      background: radial-gradient(50vw 60vw at 50% 70%, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 60%), 
              radial-gradient(35% 15% at 50% 85%, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0) 100%); 
      // filter: blur(50px);
    }
  }
}

.bgCouple {
  position: absolute;
  top: 20px;
  left: 35%;
  transform:translateX(-50%);
  width: 200px;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.popUpCouple {
  position: fixed;
  width: max(30vw, 30vh);
  height: max(30vw, 30vh);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.8s;

  &.vertical {
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    &.active {
      top: calc(100% - max(30vw, 30vh) - 10px);
    }
  }

  &.horizontal {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    &.active {
      left: calc(100% - max(30vw, 30vh) - 10px);
    }
  }

  &.emphasized {
    width: max(50vw, 50vh);
    height: max(50vw, 50vh);

    &.vertical.active {
      top: calc(100% - max(50vw, 50vh) - 10px);
    }

    &.horizontal.active {
      left: calc(100% - max(50vw, 50vh) - 10px);
    }
  }
}

.scene {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 1s;
  border-radius: 20px;
  border: solid 1px #c8c8c8;

  &.perspective {
    transform: scale(2) rotateX(68deg) rotateY(-1deg) rotateZ(48deg) translateX(47px) translateY(97px) translateZ(0px) skewX(0deg) skewY(0deg) perspective(0px);
  }
}

.cardContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -100%;
  transition: left 1.5s ease-out;

  &.in {
    left: 0;
  }
}

#error {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: "Courier", Calibri, Tahoma, sans-serif;
  font-size: 30px;
}