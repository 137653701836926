.button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    background-color: rgba(51, 51, 51, 0);
    font-family: 'Basis Grotesque Arabic Pro Md', Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.5s;

    & > span {
        transition: color 0.5s;
    }

    &.selected span {
        background: rgb(128, 110, 65);
        background: linear-gradient(90deg, rgb(170, 146, 86) 0%, rgb(220, 196, 113) 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &:not(:first-child) {
        margin-left: 50px;
    }

    &.enabled:hover,
    &.selected {
        background-color: rgba(51, 51, 51, 1);
        & > span {
            color: #EEE;
        }
    }
}