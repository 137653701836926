#cardFront {
    user-select: none;
    cursor: pointer;
}

#to {
    position: absolute;
    left: 50px;
    top: 50px;
    font-family: "Courier", Calibri, Tahoma, sans-serif;
    font-size: 40px;
}

.name {
    font-size: 280px;
    transform: rotate(-10deg);
    position: absolute;
    letter-spacing: 10px;
    background: rgb(128, 110, 65);
    background: linear-gradient(90deg, rgba(128, 110, 65, 1) 0%, rgba(192, 172, 99, 1) 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &#alexa {
        left: 24%;
        top: 12%;
    }

    &#jeffrey {
        display: inline-block;
        padding-left: 30px;
        width: 700px;
        height: 400px;
        left: 30%;
        top: 37%;
    }
}

#saveTheDate {
    font-size: 50px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 45%;
    text-align: center;
    font-weight: bold;
    letter-spacing: 8px;
    transform: translateY(-50%)
}

.info {
    font-family: "Courier", Calibri, Tahoma, sans-serif;
    position: absolute;
    bottom: 50px;
    font-size: 30px;

    &#address {
        width: 800px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }

    &#date {
        right: 50px;
        text-align: end;
        line-height: 42px;
    }
}