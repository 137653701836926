@import "../base/styles/_baseTextStyles.scss";

.button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    background-color: rgba(51, 51, 51, 0);
    font-family: 'Basis Grotesque Arabic Pro Md', Helvetica, Arial, sans-serif;
    letter-spacing: 3px;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s;

    &.expended {
        width: max(80vh, 1000px);
        min-height: 200px;
        justify-content: flex-start;
        padding-left: 50px;
        padding-right: 50px;
        border-radius: 2px;
        cursor: default;

        &>.title {
            @include goldText;
            display: inline-block;
            margin: 40px;
            font-size: 40px;
        }

        &>.body {
            display: block;
            opacity: 1;
        }
    }

    & * {
        transition: all 0.5s;
    }

    &.selected > span {
        @include goldText;
    }

    &:not(:first-child) {
        margin-left: 50px;
    }

    &.enabled:hover,
    &.selected,
    &.expended {
        background-color: rgba(51, 51, 51, 1);

        &>span {
            color: #EEE;
        }
    }

    .body {
        display: none;
        width: 100%;
        opacity: 0;

        * {
            color: #EEE;
        }

        .closeButton {
            position: absolute;
            width: 50px;
            height: 50px;
            right: 30px;
            top: 30px;
            mask: url("../../img/xmark.svg") no-repeat center;
            background-color: #EEE;
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
                transform: rotate(180deg);
            }
        }
    }
}