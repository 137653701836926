.card {
    padding: 50px;
    aspect-ratio: 1200/857;
    width: 1200px;
    height: 857px;
    transform: scale(0.7);
}

.cardFlipper {
    aspect-ratio: 1200/857;
    width: 100%;
    height: 100%;
}

.cardPage {
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    filter: drop-shadow(rgba(0, 0, 0, 0.35) 3px 3px 8px);
}

.sebastian {
    font-family: "Sebastian Bobby Alt", Calibri, Tahoma, sans-serif;
    font-size: 100px;
}


.tools {
    position: fixed;
    bottom: -25px;
    left: 0;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;


    .localeButtonContainer {
        display: flex;
        flex-direction: row;

        .localeButton {
            width: 50px;
            height: 50px;
            border-radius: 25px;
            border: solid 1px rgba(128, 110, 65, 1);
            background-color: transparent;
            color: #000;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px;

            &:hover {
                transform: scale(1.2);
            }

            &.active {
                background-color: rgba(128, 110, 65, 1);
                color: #FFF;
            }
        }
    }

    .flipTips {
        padding-right: 20px;
        font-style: italic;
        font-size: 20px;
        color: #000;

        img {
            margin-left: 5px;
            height: 50px;
        }
    }
}