.quantityPicker button {
    width: 48px;
    height: 48px;
    background: rgb(140, 140, 140);
    color: rgb(51, 51, 51);
    font-size: 24px;
    border: 0;
    cursor: pointer;
    
    &.left {
        border-radius: 10px 0 0 10px;
    }

    &.right {
        border-radius: 0 10px 10px 0;
    }

    &:hover {
        background: rgb(128, 110, 65);
    }
}

.quantityPicker .quantityContainer {
    width: 96px;
    height: 48px;
    display: inline-block;
    line-height: 48px;
    border-top: solid 1px rgb(140, 140, 140);
    border-bottom: solid 1px rgb(140, 140, 140);
    vertical-align: top;
    text-align: center;
}