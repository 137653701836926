.loader {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border: 2px solid rgb(51, 51, 51);
    position: absolute;
    left: 0;
    top: 0;
    animation: rotationBreak 3s ease-in-out infinite alternate;
  }
  .loader::after {
    border-color: rgb(170, 146, 86);
    animation-direction: alternate-reverse;
  }
  
  @keyframes rotationBreak {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
      