.triangle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 0;
    border-color: rgb(51, 51, 51) transparent transparent transparent;
    transition: border-width 0.5s;
    cursor: pointer;

    &.anim {
        animation: triangleAnimate 1s infinite;
    }

    &:hover {
        border-width: 60px 60px 0 0;
    }
}

@keyframes triangleAnimate {
    0% {
        border-width: 50px 50px 0 0;
    }

    50% {
        border-width: 60px 60px 0 0;
    }
    100% {
        border-width: 50px 50px 0 0;
    }
}